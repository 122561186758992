import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {
    Button, Container, Divider, Grid, Header, Icon, Message, Segment, Tab, Menu, Label
} from 'semantic-ui-react'
import {Input} from 'semantic-ui-react'
import './App.css'
import {Form, TextArea} from 'semantic-ui-react'
import axios from 'axios';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bootstrap: '', commands: '', selectedFile: null, dns: '', filesList: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.synchronize = this.synchronize.bind(this);
        this.fileInputRef = React.createRef()
    }

    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
        console.log('this.state', this.state)
    }

    // On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({selectedFile: event.target.files[0]});

    };
// On file upload (click the upload button)
    synchronize = () => {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append("DNS", this.state.dns);
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        axios.post("http://localhost:9000/upload", formData);
    };


    // File content to be displayed after
    // file upload is complete
    fileData = () => {

        if (this.state.selectedFile) {

            return (<Message positive style={{}}>
                <Message.Header>Selected File</Message.Header>

                <h5>Your selected file details:</h5>
                <p>File Name: {this.state.selectedFile.name}</p>
                <p>File Type: {this.state.selectedFile.type}</p>
                <p>File Size: {this.state.selectedFile.size} bytes</p>
                <p>
                    Last Modified: {new Date(this.state.selectedFile.lastModified).toLocaleString()}
                </p>
            </Message>);
        } else {
            return (<Message negative style={{alignItems: 'center'}}>
                <Message.Header as='h6' style={{fontSize: 15}}>Select File</Message.Header>
                <p style={{fontSize: 13}}>Select your files to upload to DHT network so you can have them in
                    sync</p>
            </Message>);
        }
    };


    render() {

        const panes = [{
            menuItem: (<Menu.Item key={1} style={{backgroundColor: '#ddd'}}>
                <Icon name='sync alternate'/>
                Synchronize new file
            </Menu.Item>), render: () =>
                <Tab.Pane style={{backgroundColor: '#ddd'}}>
                    <Container>
                        {/*<Header attached='top' style={{textAlign:'center'}} as='h4'>Synchronize new file</Header>*/}
                        <Segment placeholder>
                            <Grid columns={2} relaxed='very' stackable>
                                <Grid.Column>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <label style={{textAlign: 'center', fontWeight: 510}}>Enter the absolute
                                            path of
                                            the
                                            files you want to keep synchronized
                                            <br/>
                                            <br/>
                                        </label>
                                        <span style={{textAlign: 'center', fontSize: 11, marginBottom: 20}}>e.g. /etc/nginx/nginx.conf | &#60;Your-desired-DNS-name-in-DHT-network&#62;</span>
                                        <TextArea onChange={this.handleChange} style={{
                                            minWidth: '90%', border: '1px solid #ddd', borderRadius: 10, padding: 10
                                        }}
                                                  value={this.state.filesList}
                                                  placeholder='/etc/nginx/nginx.conf | {DNS-name}'/>
                                    </div>
                                </Grid.Column>

                                <Grid.Column verticalAlign='middle'>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Button
                                            content="Choose files to sync"
                                            labelPosition="left"
                                            icon="file"
                                            onClick={() => this.fileInputRef.current.click()}
                                        />
                                        <input
                                            ref={this.fileInputRef}
                                            type="file"
                                            hidden
                                            onChange={this.onFileChange}
                                        />
                                        <Input name='dns' value={this.state.dns} onChange={this.handleChange}
                                               label='DNS://'
                                               placeholder='nginx.conf'/>

                                    </div>
                                </Grid.Column>
                            </Grid>

                            <Divider vertical>Or</Divider>
                        </Segment>
                        {this.fileData()}
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button primary size='medium' onClick={this.synchronize} style={{margin: 20}}>
                                Synchronize
                                {/*<Icon name='sync icon'/>*/}
                                <i style={{marginLeft: 10}} className="sync icon"></i>
                            </Button>
                        </div>

                    </Container>
                </Tab.Pane>
        }, {
            menuItem: (<Menu.Item key={2} style={{backgroundColor: '#ffc8c8'}}>
                <Icon name='sync alternate'/>
                Synchronize existing file
            </Menu.Item>), render: () =>
                <Tab.Pane style={{backgroundColor: '#ffc8c8'}}>
                    <Container>
                        {/*<Header attached='top' style={{textAlign:'center'}} as='h4'>Synchronize new file</Header>*/}
                        <Segment placeholder>
                            <Grid relaxed='very' stackable>
                                <Grid.Column>
                                    <div >
                                        {/*<label style={{textAlign: 'center', fontWeight: 510}}>Since browsers don't let you to save files in specific directory because of security problem, in order to synchronize existing file to your desired location, use following command:</label>*/}
                                        {/*<p>docker run -d iranmanesh/sync-client &#60;whatever-node-in-dht:port&#62;</p>*/}
                                        <label style={{textAlign: 'center', fontWeight: 510}}>Synchronize existing file on DHT</label>
                                        <p>Please provide the name of the DNS file that exists on the DHT network and the path where you would like it synced on your local machine</p>
                                        <TextArea onChange={this.handleChange} style={{
                                            minWidth: '90%', border: '1px solid #ddd', borderRadius: 10, padding: 10
                                        }}
                                                  value={this.state.filesList}
                                                  placeholder='{DNS-name} | /etc/nginx/nginx.conf'/>
                                    </div>
                                </Grid.Column>

                            </Grid>

                        </Segment>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Button primary size='medium' onClick={this.synchronize} style={{margin: 20}}>
                                Synchronize
                                {/*<Icon name='sync icon'/>*/}
                                <i style={{marginLeft: 10}} className="sync icon"></i>
                            </Button>
                        </div>

                    </Container>
                </Tab.Pane>
        } ]

        const TabExampleBasic = () => <Tab panes={panes}/>

        return (<div className='container'
                     style={{
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         flexDirection: 'column',
                         minWidth: 900
                     }}>
            <img src="/logo.svg" style={{width: 300, margin: 50}} alt=""/>

            {TabExampleBasic()}


        </div>)
    }
}


export default App
